<template>
  <el-row>
    <el-col :span="5"></el-col>
    <el-col :span="14"></el-col>
    <el-col :span="5"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "Product",
};
</script>

<style>
</style>